import { createGlobalState, useStorage } from '@vueuse/core'
import flagMX from '../assets/flag_mx.png'
import flagUSA from '../assets/flag_usa.png'
import { ref, watch } from 'vue'
import moment from 'moment'
import useCart from '@/store/cart.js'
import 'moment/dist/locale/es'

export default createGlobalState(() => {
  const languages = [
    {
      id: 1,
      key: 'en-us',
      label: 'English (EN-US)',
      short: 'en',
      flag: flagUSA,
      privacy_policy: import.meta.env.VITE_PRIVACY_URL_EN
    },
    {
      id: 2,
      key: 'es-mx',
      label: 'Español (ES)',
      short: 'es',
      flag: flagMX,
      privacy_policy: import.meta.env.VITE_PRIVACY_URL_ES
    }
  ]

  const currencies = [
    {
      key: 'MXN',
      label: 'Peso (MXN)'
    },
    {
      key: 'USD',
      label: 'Dollar (USD)'
    }
  ]

  const defaultSettings = { lang: 'es-mx', currency: 'MXN' }
  const userSettings = useStorage('site-settings', defaultSettings)
  const currentLanguage = ref(languages.find(lang => lang.key === userSettings.value.lang))
  const currentCurrency = currencies.find(currency => currency.key === userSettings.value.currency)
  const showCommissions = ref(userSettings.value.showCommissions ?? false)

  moment.locale(currentLanguage.value.short)

  watch(showCommissions, (value) => {
    userSettings.value = { ...userSettings.value, showCommissions: value }
  })

  function changeCurrentLanguage (key) {
    currentLanguage.value = languages.find(lang => lang.key === key)
    userSettings.value = { lang: key, currency: userSettings.value.currency }
    window.location.reload()
  }

  function changeCurrentCurrency (key) {
    currentCurrency.value = currencies.find(currency => currency.key === key)
    userSettings.value = { lang: userSettings.value.lang, currency: key }
    const { clearCart } = useCart()
    clearCart()

    window.location.reload()
  }

  return {
    currentLanguage,
    languages,
    changeCurrentLanguage,
    currentCurrency,
    currencies,
    changeCurrentCurrency,
    showCommissions
  }
})
