import { createGlobalState } from '@vueuse/core'
import { readonly, ref } from 'vue'
export default createGlobalState(() => {
  const isSidebarOpen = ref(true)

  function openSidebar () {
    isSidebarOpen.value = true
  }

  function closeSidebar () {
    isSidebarOpen.value = false
  }

  function toggleSidebar () {
    isSidebarOpen.value = !isSidebarOpen.value
  }

  return {
    isSidebarOpen: readonly(isSidebarOpen),
    openSidebar,
    closeSidebar,
    toggleSidebar
  }
})
