import { createRouter, createWebHistory } from 'vue-router'
import auth from '@/store/auth.js'
import mobileSidebarStore from '@/store/mobileSidebar.js'
import useProfileMenu from '@/store/profileMenu.js'

const { closeSidebar } = mobileSidebarStore()
const {
  getUser,
  user
} = auth()
const { isProfileMenuOpen } = useProfileMenu()

const routes = [
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/Login.vue'),
    beforeEnter: async () => {
      if (user.value?.id) await router.push({ name: 'home' })
    }
  },
  {
    name: 'set-password',
    path: '/set-password',
    component: () => import('@/views/SetPassword.vue')
  },
  {
    name: 'forgot-password',
    path: '/forgot-password',
    component: () => import('@/views/ForgotPassword.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    redirect: {
      name: 'error',
      params: { code: 404 }
    }
  },
  {
    name: 'error',
    path: '/error/:code',
    props: route => ({ code: Number(route.params.code) }),
    component: () => import('@/views/ErrorPage.vue')
  },
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        name: 'home',
        path: '',
        redirect: { name: 'hotels' }
      },
      {
        path: '/agency',
        component: () => import('@/layouts/AgencyLayout.vue'),
        children: [
          {
            path: 'reservations',
            children: [
              {
                name: 'reservations',
                path: '',
                props: ({ query }) => ({ search: query.search }),
                component: () => import('@/views/Reservations.vue')
              },
              {
                name: 'reservations.items',
                path: ':id/items',
                props: ({ params }) => ({ id: params.id }),
                component: () => import('@/views/ReservationItems.vue')
              },
              {
                name: 'reservations.details',
                path: ':id/details',
                props: ({ params }) => ({ id: params.id }),
                component: () => import('@/views/ReservationDetails.vue')
              }
            ]
          },
          {
            path: 'agents',
            children: [
              {
                name: 'agents',
                path: '',
                component: () => import('@/views/Agents.vue'),
                meta: { ownerOnly: true }
              },
              {
                name: 'agents.form',
                path: 'create/:id?',
                props: ({ params }) => ({ id: Number(params.id) }),
                component: () => import('@/views/AgentForm.vue'),
                meta: { ownerOnly: true }
              }
            ]
          }
        ]
      },
      {
        path: '/hotels',
        children: [
          {
            name: 'hotels',
            path: '',
            props: { booker: 'hotels' },
            component: () => import('@/views/HotelHome.vue')
          },
          {
            name: 'hotels.booker',
            path: 'booker',
            component: () => import('@/views/HotelBooker.vue')
          },
          {
            name: 'hotels.rooms',
            path: ':id/rooms',
            props: route => ({ id: Number(route.params.id) }),
            component: () => import('@/views/HotelRooms.vue')
          },
          {
            name: 'hotels.checkout',
            path: ':id/checkout',
            props: route => ({ id: Number(route.params.id) }),
            component: () => import('@/views/HotelCheckout.vue')
          },
          {
            name: 'hotels.cross',
            path: ':id/order-resume',
            props: route => ({ id: Number(route.params.id) }),
            component: () => import('@/views/HotelCrossSell.vue')
          }
        ]
      },
      {
        path: '/experiences',
        children: [
          {
            name: 'experiences',
            path: '',
            props: { booker: 'experiences' },
            component: () => import('@/views/ExperienceHome.vue')
          },
          {
            name: 'experiences.booker',
            path: 'booker',
            component: () => import('@/views/ExperienceBooker.vue')
          },
          {
            name: 'experiences.details',
            path: ':id/details',
            props: route => ({ id: Number(route.params.id) }),
            component: () => import('@/views/ExperienceDetails.vue')
          },
          {
            name: 'experiences.checkout',
            path: ':id/checkout',
            props: route => ({ id: Number(route.params.id) }),
            component: () => import('@/views/ExperienceCheckout.vue')
          }
        ]
      },
      {
        path: '/transfers',
        children: [
          {
            name: 'transfers',
            props: { booker: 'transfers' },
            path: '',
            component: () => import('@/views/TransferHome.vue')
          },
          {
            name: 'transfers.booker',
            path: 'booker',
            component: () => import('@/views/TransferBooker.vue')
          },
          {
            name: 'transfers.checkout',
            path: ':id/checkout',
            props: route => ({ id: Number(route.params.id) }),
            component: () => import('@/views/TransferCheckout.vue')
          }
        ]
      },
      {
        name: 'payment',
        path: 'payment',
        component: () => import('@/views/Payment.vue'),
        meta: { hideLocalization: true }
      },
      {
        name: 'payment.success',
        path: 'payment-success',
        component: () => import('@/views/PaymentSuccess.vue')
      },
      {
        name: 'payment.error',
        path: 'payment-error',
        component: () => import('@/views/PaymentError.vue')
      }
    ]
  },
  {
    name: 'payment.response',
    path: '/payment/response',
    props: route => ({ query: route.query }),
    component: () => import('@/views/PaymentResponse.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

router.beforeEach(async (to, from) => {
  closeSidebar()
  isProfileMenuOpen.value = false
  const user = await getUser()
  if (to.meta.redirectAdmin && user?.is_admin) {
    await router.push({ name: 'home' })
    return
  }
  if (to.meta.ownerOnly && !user?.is_owner) {
    await router.push({ name: 'home' })
    return
  }
  if (to.name === 'login' || to.name === 'set-password' || to.name === 'forgot-password' || to.name === 'error' || to.name === 'payment.response') return
  if (!user) {
    await router.push({ name: 'login', query: { redirect: (location.pathname + location.search) } })
  }
})

export default router
