import { createGlobalState } from '@vueuse/core'
import { ref } from 'vue'
import http from '@/http.js'
import router from '@/router.js'
export default createGlobalState(() => {
  const user = ref({})
  const userCommission = ref()

  async function login (email, password) {
    const dataForm = new FormData()
    dataForm.append('email', email)
    dataForm.append('password', password)
    await http({ interceptors: false })({
      method: 'post',
      data: dataForm,
      url: 'login',
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }

  async function logout () {
    await http()({
      method: 'post',
      url: 'logout',
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    user.value = {}
    await router.push({ name: 'login' })
  }

  async function getUser () {
    try {
      const { data } = await http({ interceptors: false }).get('user')
      user.value = data
      return data
    } catch (e) {
      user.value = {}
      return null
    }
  }

  return {
    login,
    logout,
    user,
    userCommission,
    getUser
  }
})
