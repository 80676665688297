import axios from 'axios'
import useUserSettings from '@/store/userSettings.js'
import router from '@/router.js'

const { currentLanguage, currentCurrency } = useUserSettings()

export default function http ({ interceptors = true } = {}) {
  const axiosInstance = axios.create({
    baseURL: import.meta.env.VITE_BFF_URL,
    headers: {
      Accept: 'application/json',
      withCredentials: true
    },
    timeout: 45000
  })

  if (!interceptors) return axiosInstance

  axiosInstance.interceptors.request.use(config => {
    config.params = {
      lang: currentLanguage.value.id,
      currency: currentCurrency.key.toLowerCase(),
      ...config.params
    }
    return config
  })

  axiosInstance.interceptors.response.use((response) => {
    return response
  }, async (error) => {
    if (error.response?.status === 401 && router.currentRoute.value.name !== 'login') {
      await router.push({ name: 'login', query: { redirect: (location.pathname + location.search) } })
    }
    if (error.response?.status === 500) {
      await router.push({ name: 'error', params: { code: 500 } })
    }
    if (error.response?.status === 404) {
      await router.push({ name: 'error', params: { code: 404 } })
    }
    return Promise.reject(error)
  })

  return axiosInstance
}
