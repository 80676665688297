<script setup>
import useFullScreenLoading from '@/store/fullScreenLoading.js'

const { isFullScreenLoading } = useFullScreenLoading()
</script>

<template>
  <div
    v-if="isFullScreenLoading"
    class="fixed h-screen w-screen z-50 bg-white/80 flex items-center justify-center overflow-hidden"
  >
    <div class="lds-ripple scale-150">
      <div /><div />
    </div>
  </div>
</template>

<style scoped>
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #2188c9;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
