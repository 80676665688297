<script setup>
import FullScreenLoader from '@/components/FullScreenLoader.vue'
import MobileSidebar from '@/components/MobileSidebar.vue'

document.documentElement.setAttribute('data-theme', 'light')

const body = document.querySelector('body')
body.style.setProperty('--primary-bg-color', 'linear-gradient(90deg, #4CB8C4 54.09%, #3CD3AD 119.38%)')
body.style.setProperty('--primary-contrast-color', '#fff')
body.style.setProperty('--secondary-bg-color', '#FD4659')
body.style.setProperty('--warning-bg-color', '#ded151')
body.style.setProperty('--secondary-contrast-color', '#fff')
body.style.setProperty('--login-bg-gradient', 'linear-gradient(90deg, #4CB8C4 60.26%, #3CD3AD 139.83%)')
body.style.setProperty('--primary-font', 'Gothic')
body.style.setProperty('--border-radius', '0.5rem')
body.style.setProperty('--footer-bg-color', '#585857')

</script>

<template>
  <FullScreenLoader />
  <MobileSidebar />
  <button
    class="hidden fixed top-0 right-0 text-blue-500"
    @click="changeFeel"
  >
    Cambiar branding
  </button>
  <div
    ref="app"
    class="light"
  >
    <router-view />
  </div>
</template>
<style>

@font-face {
  font-family: Gothic;
  src: url('@/assets/fonts/gothic.ttf');
}

@font-face {
  font-family: Open Sans;
  src: url('@/assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
}

body {
  background: #FBFBFB;
  font-family: var(--primary-font), sans-serif;

  --primary-font: Gothic;

  --primary-bg-color: #000;
  --primary-accent-color: #000;

  --warning-bg-color: #000;

  --secondary-bg-color: #000;
  --secondary-accent-color: #000;

  --login-bg-gradient: #000;

  --header-color: #fff;
  --footer-bg-color: #fff;
  --primary-color-gradient: #313131;
  --primary-contrast-coloraccent-color: #fff;
  --border-radius: '0';
}

* {
  scrollbar-width: thin;
  scrollbar-color: #4b5a72 #96a1af;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #96a1af;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #4b5a72;
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 200,
    'GRAD' 0,
    'opsz' 20
}

.star-icon {
  font-size: 16px;
  font-variation-settings: 'FILL' 1,
  'wght' 100,
  'GRAD' 0,
  'opsz' 20
}

.icon-filled {
  font-variation-settings: 'FILL' 1,
  'wght' 100,
  'GRAD' 0,
  'opsz' 20
}

.icon-strong {
  font-variation-settings:
  'FILL' 1,
  'wght' 200,
  'GRAD' 0,
  'opsz' 20
}
</style>
