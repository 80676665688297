import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import messages from '@/i18n/langs.js'
import router from '@/router'
import './style.css'
import primeStyles from '@/prime'
import App from './App.vue'
import useUserOptionsStore from '@/store/userSettings.js'
import VueSnip from 'vue-snip'
import * as Sentry from '@sentry/vue'
import PrimeVue from 'primevue/config'

const { currentLanguage } = useUserOptionsStore()
const i18n = createI18n({
  locale: currentLanguage.value.key,
  legacy: false,
  fallbackLocale: 'es-mx',
  messages,
  warnHtmlMessage: false
})

const app = createApp(App)

if (import.meta.env.VITE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: 'https://629f4add65bbf212db4e95471e168bf2@o4506196372094976.ingest.sentry.io/4506219411210240',
    ignoreErrors: [/401/, /ResizeObserver/],
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

app.use(router)
app.use(VueSnip)
app.use(i18n)
app.use(PrimeVue, { unstyled: true, pt: primeStyles })
app.mount('#app')
