<script setup>
import mobileSidebarStore from '@/store/mobileSidebar.js'
import InternationalizationSettings from '@/components/InternationalizationSettings.vue'
import auth from '@/store/auth.js'
import { computed } from 'vue'
const { isSidebarOpen, closeSidebar } = mobileSidebarStore()

const { user } = auth()
const showAgentsTab = computed(() => user.value?.is_owner)

</script>
<template>
  <div
    v-if="isSidebarOpen"
    class="fixed h-screen w-[90%] z-50 bg-white overflow-hidden p-12"
  >
    <div class="fixed left-0 top-1">
      <button
        @click="closeSidebar"
      >
        <span class="material-symbols-outlined text-4xl">cancel</span>
      </button>
    </div>
    <div class="flex justify-center">
      <img
        class="h-16"
        src="@/assets/mh_logo.jpg"
        alt="Mar Holidays"
      >
    </div>
    <div class="mt-4 text-center">
      <InternationalizationSettings />
    </div>
    <div class="my-6 flex opacity-80 mb-3 font-bold">
      <div class="mr-2">
        {{ $t('booking') }}
      </div>
      <div class="flex-1 border border-transparent border-t-gray-600 translate-y-1/2" />
    </div>
    <div class="flex flex-col gap-5 text-[#4CB8C4] text-2xl">
      <router-link
        :to="{ name: 'hotels' }"
        active-class="border-b-2 border-b-[--secondary-bg-color]"
        class="flex items-center cursor-pointer pb-1"
      >
        <span class="material-symbols-outlined">hotel</span>
        <span class="ml-1">{{ $t('hotels') }}</span>
      </router-link>
      <router-link
        :to="{ name: 'experiences' }"
        active-class="border-b-2 border-b-[--secondary-bg-color]"
        class="flex items-center cursor-pointer pb-1"
      >
        <span class="material-symbols-outlined">scuba_diving</span>
        <span class="ml-1">{{ $t('experiences') }}</span>
      </router-link>
      <router-link
        :to="{ name: 'transfers' }"
        active-class="border-b-2 border-b-[--secondary-bg-color]"
        class="flex items-center cursor-pointer pb-1"
      >
        <span class="material-symbols-outlined">airport_shuttle</span>
        <span class="ml-1">{{ $t('transfers') }}</span>
      </router-link>
    </div>
    <div
      class="my-6 flex opacity-80 mb-3 font-bold"
    >
      <div class="mr-2">
        {{ $t('my_agency') }}
      </div>
      <div class="flex-1 border border-transparent border-t-gray-600 translate-y-1/2" />
    </div>
    <div
      class="flex flex-col gap-5 text-[#4CB8C4] text-2xl"
    >
      <router-link
        :to="{ name: 'reservations' }"
        active-class="border-b-2 border-b-[--secondary-bg-color]"
        class="flex items-center cursor-pointer pb-1"
      >
        <span class="material-symbols-outlined">checklist</span>
        <span class="ml-1">{{ $t('reservations') }}</span>
      </router-link>
      <router-link
        v-if="showAgentsTab"
        :to="{ name: 'agents' }"
        active-class="border-b-2 border-b-[--secondary-bg-color]"
        class="flex items-center cursor-pointer pb-1"
      >
        <span class="material-symbols-outlined">account_box</span>
        <span class="ml-1">{{ $t('agents') }}</span>
      </router-link>
    </div>
  </div>
</template>
